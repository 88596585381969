import { useDispatch, useSelector } from 'react-redux';
import Navbar from '../../components/navbar/Navbar';
import './Cart.css';
import { useEffect, useState } from 'react';
import CartList from '../../components/CartList/CartList';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { add } from '../../redux/cartSlice'
import { Tooltip } from '@mui/material';

const tip = (<div><strong>DELIVERY WILL BE DONE THE FOLLOWING WEEK WHEN WE RECEIVE THE PAYMENT UNTIL TUESDAY WEATHER AND CONDITIONS PERMITTING:</strong><br></br>
DELIVERIES AT ADDRESSES IN THE FOLLOWING LOCATIONS IN MALTA WILL BE DONE ON A TUESDAY AS PER THE INDICATED ROUTE:<br></br>
VALLETTA, SANTA VENERA,HAMRUN, PIETA,FLORIANA, MSIDA, TA'XBIEX, GZIRA, SLIEMA, ST.JULIANS, SAN GWANN, SWIEQI, MADLIENA, PEMBROKE, BAHAR IC-CAGHAQ<br></br><br></br>

DELIVERIES AT ADDRESSES IN THE FOLLOWING LOCATIONS IN MALTA WILL BE DONE ON A WEDNESDAY AS PER THE INDICATED ROUTE:<br></br>
 BIRKIRKARA, SWATAR, IKLIN, BALZAN, LIJA, ATTARD, MOSTA,NAXXAR, GHARGHUR, BURMARRAD, SAN PAWL IL-BAHAR, MELLIEHA, MANIKATA, MGARR,MTARFA, RABAT, DINGLI<br></br><br></br>

DELIVERIES AT ADDRESSES IN THE FOLLOWING LOCATIONS IN MALTA WILL BE DONE ON A THURSDAY AS PER THE INDICATED ROUTE:<br></br>
ZEBBUG, SIGGIEWI, QORMI, MARSA,BORMLA, BIRGU, ISLA, KALKARA, FGURA, PAOLA ,TARXIEN,SANTA LUCIJA, XGHAJRA,ZABBAR, MARSASCALA, ZEJTUN ,MARSAXLOKK, BIRZEBBUGIA, GHAXAQ ,GUDJA, KIRKOP ,MQABBA,SAFI ,ZURRIEQ, QRENDI,LUQA<br></br><br></br>

DELIVERIES AT ADDRESSES IN THE FOLLOWING LOCATIONS IN GOZO WILL BE DONE ON A FRIDAY AS PER THE INDICATED ROUTE:<br></br>
GHAJNSIELEM, QALA, XEWKIJA,NADUR, XAGHRA,SANNAT,KERCEM, MUNXAR,VICTORIA, FONTANA, XLENDI, GHARB,GHASRI, SAN LAWRENZ ,ZEBBUG ,MARSALFORN</div>);

const Cart = () => {
    const cart = useSelector((state) => state.cartReducer.items)
    const [cartItems, setCartItems] = useState([]);
    const [surcharge, setSurcharge] = useState(0);
    const [shipping, setShipping] = useState(0);
    const [VAT, setVAT] = useState(0);
    const [total, setTotal] = useState(0);
    const [addSKU, setAddSKU] = useState("");

    const dispatch = useDispatch()
    const navigate = useNavigate();

    const mapCartItems = () => {
        let items = [];

        if (cart.length === 0) {
            setCartItems([]);
            return;
        }
    
        let promises = cart.map(async item => {
            let stock, probability;
    
            await fetch(`${process.env.REACT_APP_API}/api/getProduct/${item.id}`, {})
                .then(response => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        throw new Error('Failed to fetch');
                    }
                })
                .then(data => {
                    stock = data.stock;
                    probability = data.probability;
                })
                .catch(error => {
                    console.error('Error fetching product data:', error);
                    stock = null;
                    probability = null;
                });
    
            items.push({
                id: item.id,
                name: item.name,
                price: item.price,
                img: item.img,
                amount: item.amount,
                stock: stock,
                probability: probability,
            });
        });
    
        Promise.all(promises).then(() => {
            setCartItems(items.sort((a, b) => a.name.localeCompare(b.name)));
        }).catch(error => {
            console.error('Error processing cart items:', error);
        });
    }
    
    useEffect(() => {
        mapCartItems();
    }, [cart]);
    

    useEffect(() => {
        calculateTotal(cart)
    }, [cart]);

    useEffect(() => {
        calculateVAT(cart)
    }, [surcharge]);

    useEffect(() => {
        calculateVAT(cart)
    }, [shipping]);

    const calculateTotal = (cart) => {
        let sum = 0;

        cart.forEach(item => {
            sum += (item.price * item.amount)
        });

        setTotal(sum);
        calculateSurcharge(cart);
    }

    const calculateSurcharge = (cart) => {
        let sum = 0;

        cart.forEach(item => {
            sum += (item.price * item.amount)
        });

        if (sum === 0) setSurcharge(0)
        else if (sum < 100) setSurcharge(10)
        else setSurcharge(0)
        calculateShipping(cart);
    }

    const calculateShipping = (cart) => {
        let sum = 0;

        cart.forEach(item => {
            sum += (item.price * item.amount)
        });

        if (sum > 500) setShipping(sum * 0.23)
        else setShipping(sum * 0.25, () => calculateVAT(cart))
    }

    const calculateVAT = (cart) => {
        let sum = 0;

        cart.forEach(item => {
            sum += (item.price * item.amount)
        });

        setVAT((sum + surcharge + shipping) / 1.18 * 0.18);
    }

    const handleChangeAdd = (e) => {
        setAddSKU(e.target.value);
    }

    const handleAdd = () => {
        fetch(`${process.env.REACT_APP_API}/api/getProductsByName/${addSKU.trim()}/0/10`, {})
        .then((response) => response.json())
        .then((responseData) => {
            if (responseData.length > 0) {
                handleAddItem({
                    id: responseData[0].product.id,
                    name: responseData[0].product.name,
                    price: responseData[0].product.salesPrice.numeral,
                    img: responseData[0].product.mainImageUrl,
                    amount: 1,
                })
            } else {
                toast.error(`${addSKU} not found!`, {
                    toastId: Math.floor(Math.random() * 100000),
                })
            }
        })
        .catch(error => console.warn(error));
    }

    const handleAddItem = (data) => {
        dispatch(add({
            id: data.id,
            name: data.name,
            price: data.price,
            img: data.img,
            amount: data.amount,
        }))

        toast.success(`${data.name} added to cart!`, {
            toastId: Math.floor(Math.random() * 100000),
        })
    
        setAddSKU("")
    }
    
    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
          handleAdd();
        }
    };

    return (
        <div className="cart">
            <Navbar />
            
            <div className="cart-wrapper">
                <div>
                    <div className="home-input-group mt-50 mb-20" style={{ justifyContent: 'flex-end' }}>
                        <input value={addSKU} onChange={(e) => handleChangeAdd(e)} placeholder='SKU' onKeyDown={handleKeyDown}></input>
                        <button onClick={handleAdd}>Add</button>
                    </div>

                    <CartList data={cartItems} noMargin={true} showcase={false} />
                </div>

                <div className="cart-price mt-50">
                    <small>* All prices are inclusive of VAT</small>
                    <div className="order-pricing">
                        <p>ORDER TOTAL</p>
                        <h4>€{total.toFixed(2)}</h4>
                    </div>
                    <div className="order-pricing">
                        <p>SHIPPING</p>
                        <h4>€{shipping.toFixed(2)}</h4>
                    </div>
                    <div className="order-pricing">
                        <p>SURCHARGE</p>
                        <h4>€{surcharge.toFixed(2)}</h4>
                    </div>
                    {/* <div className="order-pricing">
                        <p>SUB TOTAL</p>
                        <h4>€749.00</h4>
                    </div> */}
                    {/* <div className="order-pricing">
                        <p>18% VAT</p>
                        <h4>€{VAT.toFixed(2)}</h4>
                    </div> */}
                    <hr></hr>
                    <div className="order-pricing">
                        <p>GRAND TOTAL</p>
                        <h4>€{(total + surcharge + shipping).toFixed(2)}</h4>
                    </div>
                    <div className="cart-price-button">
                        <button disabled={total === 0} onClick={() => navigate('/order')}>Order</button>
                    </div>
                    <small>* The item/s you are ordering will be delivered to you asap depending on the availability of stock from shop. Kindly contact us for more information regarding this by email.</small><br></br><br></br>
                    <small>Please check the delivery <Tooltip disableFocusListener title={tip} placement="top"><span style={{ textDecoration: 'underline' }}>schedule</span></Tooltip> for more information.</small>
                </div>
            </div>
        </div>
    );
}

export default Cart;