import Navbar from '../../components/navbar/Navbar';
import './createCreditNote.css';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import OrdersList from '../../components/OrdersList/OrdersList';
import CartList from '../../components/CartList/CartList';
import { toast } from 'react-toastify';

const steps = ['Select order', 'Configure credit note', 'Review'];

const CreateCreditNote = () => {
    const [orders, setOrders] = useState([]);
    const [order, setOrder] = useState(undefined);
    const [selectedOrder, setSelectedOrder] = useState(undefined);
    const [creditTotal, setCreditTotal] = useState(0);
    const [activeStep, setActiveStep] = React.useState(0);
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        if (localStorage.getItem('admin')) {
            getOrders();
            if (params.id) {
                getOrder(params.id);
                setSelectedOrder(params.id)
                setActiveStep(1)
            }
        } else {
            navigate('/');
        }
    }, []);

    const getOrders = () => {
        let url = `${process.env.REACT_APP_API}/api/getAllOrders`;

        fetch(url, {})
        .then(response => {
            if (response.status === 200) {
                if (localStorage.getItem('account') !== null) {
                    response.json().then(data => setOrders(data));
                } else {
                    navigate('/')
                }
            }
        })
    }

    const getOrder = (id) => {
        fetch(`${process.env.REACT_APP_API}/api/getOrder/${id}`, {})
        .then(response => {
            if (response.status === 200) {
                if (localStorage.getItem('account') !== null) {
                    response.json().then(data => setOrder({...data, cart: data.cart.map(x => {return {...x, creditAmount: 0}})}));
                } else {
                    navigate('/')
                }
            }
        })
    }

    const select = (id) => {
        setSelectedOrder(id);
        getOrder(id);
        setCreditTotal(0)
    }
    
    const handleNext = () => {
        if (activeStep == 2) create();
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const updateCart = (cart) => {
        setOrder({...order, cart: cart});
        calculateCreditTotal(cart)
    }

    const calculateCreditTotal = (cart) => {
        let total = 0;

        for (let i = 0; i < cart.length; i++) {
            const item = cart[i];
            
            if (item.creditAmount > 0) total += item.creditAmount * item.price
        }

        setCreditTotal(total);
    }

    const create = () => {
        let tempItems = []

        for (let i = 0; i < order.cart.length; i++) {
            const item = order.cart[i];
            
            if (item.creditAmount > 0) tempItems.push({
                SKU: item.id,
                amount: item.creditAmount,
                price: item.price
            })
        }

        fetch(`${process.env.REACT_APP_API}/api/changeCreditNote/${order._id}`, {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                creditNote: tempItems,
            })
        })
        .then(response => {
            if (response.status === 200) {
                toast.success('Created Credit Note!');
                navigate('/creditnotes')
            } else if (response.status === 500) {
                toast.error('Server error.');
            }
        });
    }

    return (
        <div className="orders mb-50">
            <Navbar />
            
            <h1 className="mt-50 mb-30 page-title">Credit Notes</h1>
            <Container maxWidth="lg" className="mt-20">
                <Box sx={{ width: '100%' }}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                        return (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        );
                        })}
                    </Stepper>
                        <React.Fragment>
                            {activeStep === 0 ? (
                                <div className='mt-20'>
                                    {orders.length > 0 && <OrdersList data={orders} invoices={false} selectMode={true} select={select} selectedOrder={selectedOrder} />}
                                </div>
                            ) : (activeStep === 1 ? (
                                <div className='mt-20'>
                                    {order && <CartList data={order.cart} showcase={true} update={updateCart} creditnote={true} />}
                                    <div className="input-group" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', width: '100%', height: 'fit-content', marginTop: '20px' }}>
                                        <label style={{ fontSize: '16px', marginRight: '20px' }}>Order Total</label>
                                        <p style={{ margin: '0px' }}>- €{creditTotal.toFixed(2)}</p>
                                    </div>

                                    <div className="input-group" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', width: '100%', height: 'fit-content' }}>
                                        <label style={{ fontSize: '16px', marginRight: '20px' }}>Surcharge Total</label>
                                        <p style={{ margin: '0px' }}>- €{(0).toFixed(2)}</p>
                                    </div>

                                    <div className="input-group" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', width: '100%', height: 'fit-content' }}>
                                        <label style={{ fontSize: '16px', marginRight: '20px' }}>Shipping Total</label>
                                        <p style={{ margin: '0px' }}>- €{(0).toFixed(2)}</p>
                                    </div>

                                    <div className="input-group" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', width: '100%', height: 'fit-content' }}>
                                        <label style={{ fontSize: '16px', marginRight: '20px' }}>VAT (18%)</label>
                                        <p style={{ margin: '0px' }}>- €{(creditTotal / 1.18 * 0.18).toFixed(2)}</p>
                                    </div>

                                    <div className="input-group" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', width: '100%', height: 'fit-content' }}>
                                        <label style={{ fontSize: '16px', marginRight: '20px' }}>Combined Total</label>
                                        <p style={{ margin: '0px' }}>- €{creditTotal.toFixed(2)}</p>
                                    </div>
                                </div>
                            ) : (
                                <div className='mt-20'>
                                    {order && <CartList data={order.cart.filter(x => x.creditAmount > 0).map(x => {return {...x, amount: x.creditAmount}})} showcase={true} />}
                                    <div className="input-group" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', width: '100%', height: 'fit-content', marginTop: '20px' }}>
                                        <label style={{ fontSize: '16px', marginRight: '20px' }}>Order Total</label>
                                        <p style={{ margin: '0px' }}>- €{creditTotal.toFixed(2)}</p>
                                    </div>

                                    <div className="input-group" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', width: '100%', height: 'fit-content' }}>
                                        <label style={{ fontSize: '16px', marginRight: '20px' }}>Surcharge Total</label>
                                        <p style={{ margin: '0px' }}>- €{(0).toFixed(2)}</p>
                                    </div>

                                    <div className="input-group" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', width: '100%', height: 'fit-content' }}>
                                        <label style={{ fontSize: '16px', marginRight: '20px' }}>Shipping Total</label>
                                        <p style={{ margin: '0px' }}>- €{(0).toFixed(2)}</p>
                                    </div>

                                    <div className="input-group" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', width: '100%', height: 'fit-content' }}>
                                        <label style={{ fontSize: '16px', marginRight: '20px' }}>VAT (18%)</label>
                                        <p style={{ margin: '0px' }}>- €{(creditTotal / 1.18 * 0.18).toFixed(2)}</p>
                                    </div>

                                    <div className="input-group" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', width: '100%', height: 'fit-content' }}>
                                        <label style={{ fontSize: '16px', marginRight: '20px' }}>Combined Total</label>
                                        <p style={{ margin: '0px' }}>- €{creditTotal.toFixed(2)}</p>
                                    </div>
                                </div>
                            ))}

                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Button
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                                >
                                Back
                                </Button>
                                <Box sx={{ flex: '1 1 auto' }} />
                                <Button style={{ color: (activeStep === 0 && order === undefined) ? 'rgba(0, 0, 0, 0.26)' : '#8CBD22'}} onClick={handleNext} disabled={(activeStep === 0 && order === undefined)}>
                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                </Button>
                            </Box>
                        </React.Fragment>
                </Box>
            </Container>
        </div>
    );
}

export default CreateCreditNote;