import { useNavigate } from 'react-router-dom';
import './ProductItem.css';
import { useDispatch } from 'react-redux'
import { add } from '../../redux/cartSlice'
import { toast } from 'react-toastify';
import { useState } from 'react';
import { FaCartPlus } from 'react-icons/fa';

const ProductItem = ({ data, small = false, updateProduct = undefined }) => {
    let navigate = useNavigate();
    const [amount, setAmount] = useState(1);
    const dispatch = useDispatch()

    const handleChange = (e) => {
        e.preventDefault;

        setAmount(e.target.value);
    }

    const handleAdd = (data) => {
        if (updateProduct !== undefined) {
            updateProduct(data, amount)
        } else {
            dispatch(add({
                id: data.product.id,
                name: data.product.name,
                price: data.product.salesPrice.numeral,
                img: data.product.mainImageUrl,
                amount: amount,
            }))
        }
        toast.success(`${data.product.name} added to cart!`, {
            toastId: Math.floor(Math.random() * 100000),
        })
    }

    return (
        <div className={small ? 'product product-small' : 'product'}>
            <div className="imgWrapper" onClick={() => updateProduct ? handleAdd(data) : navigate(`/product/${data.product.id}`)}>
                {data.product?.contextualImageUrl && <img src={data.product.contextualImageUrl} alt={data.product.mainImageAlt} className="altImg"></img>}
                {data.product?.mainImageUrl && <img src={data.product.mainImageUrl} alt={data.product.mainImageAlt} className="mainImg"></img>}
            </div>
            <h4 className="product-productname" onClick={() => updateProduct ? handleAdd(data) : navigate(`/product/${data.product.id}`)}>{data.product.name}</h4>
            <p className="product-productfullname" onClick={() => updateProduct ? handleAdd(data) : navigate(`/product/${data.product.id}`)}>{data.product.mainImageAlt.slice(data.product.name.length + 1)}</p>
            <small className="product-productsku mt-10">{data.product.itemNoGlobal.replace(/(\d{3})(?=\d)/g, '$1.')}</small>
            <p className="product-productprice mt-10" onClick={() => updateProduct ? handleAdd(data) : navigate(`/product/${data.product.id}`)}>€{data.product.salesPrice.numeral.toFixed(2)}</p>
            <div className="product-tools">
                <input type="number" min="1" value={amount} onChange={handleChange} ></input>
                <button
                    className='homeButton'
                    onClick={() => handleAdd(data)}
                ><FaCartPlus></FaCartPlus></button>
            </div>
        </div>
    );
};

export default ProductItem