import { useEffect } from 'react';
import './ProductPopup.css';
import { useState } from 'react';
import ProductItem from '../ProductItem/ProductItem';

const ProductPopup = ({ setIsModalOpen, updateProduct }) => {
    const [search, setSearch] = useState("");
    const [productsIndex, setProductsIndex] = useState(0);
    const [products, setProducts] = useState([]);
    const [pageSize, setPageSize] = useState(10);

    useEffect(() => {
        window.scrollTo(0, 0)
        document.getElementById('body').classList.add('modal-open');
    }, [])

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API}/api/getProducts/0/${pageSize}`, {})
        .then(response => {
            if (response.status === 200) {
                response.json().then(data => setProducts(data));
            }
        })
    }, []);

    const closeModal = () => {
        document.getElementById('body').classList.remove('modal-open');
        setIsModalOpen(false);
    }

    const handleChangeSearch = (e) => {
        setSearch(e.target.value);
    }

    const handleSearch = () => {
        setProducts([]);
        setProductsIndex(0);
        getProductsByName(search, 0);
    }

    const getProductsByName = (name, index) => {
        console.log(index);
        fetch(`${process.env.REACT_APP_API}/api/getProductsByName/${name.trim()}/${index}/${pageSize}`, {})
        .then(response => {
            if (response.status === 200) {
                response.json().then(data => {
                    setProducts(data)
                });
            }
        })
    }

    const prevPage = () => {
        if (productsIndex === 0) return;
        if (search !== "") {
            getProductsByName(search, productsIndex - 1);
        } else {
            getProducts(productsIndex - 1);
        }
        setProductsIndex(productsIndex - 1);
    }

    const nextPage = () => {
        if (search !== "") {
            getProductsByName(search, productsIndex + 1);
        } else {
            getProducts(productsIndex + 1);
        }
        setProductsIndex(productsIndex + 1);
    }

    const getProducts = (index) => {
        fetch(`${process.env.REACT_APP_API}/api/getProducts/${index}/${pageSize}`, {})
        .then(response => {
            if (response.status === 200) {
                response.json().then(data => {
                    setProducts(data)
                });
            }
        })
    }

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
          handleSearch();
        }
    };

    return (
        <div className="productPopup-wrapper">
            <div className='productPopup'>
                <div style={{ paddingTop: '2.5%' }}>
                    <h2 className='mt-0'>Add Products</h2>
                    <div className="home-input-group pb-30" style={{ height: '32px', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex' }}>
                            <input value={search} onChange={(e) => handleChangeSearch(e)} onKeyDown={handleKeyDown}></input>
                            <button onClick={handleSearch} className='mt-0' style={{ height: '32px' }}>Search</button>

                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px', marginLeft: '20px' }}>
                                <button className="homePagingButton" onClick={() => prevPage()}>Prev Page</button>
                                <p style={{ margin: '0px' }}>Page: {productsIndex}</p>
                                <button className="homePagingButton" onClick={() => nextPage()}>Next Page</button>
                            </div>
                        </div>
                        <button onClick={closeModal} className='mt-0' style={{ height: '32px' }}>Close</button>
                    </div>
                </div>
                {products.length > 0 && <div className='homeProducts' style={{ width: '100%', overflow: 'scroll' }}>
                    <div className='homeProductsContainer' id='target'>
                        {products && products.map((product) => {
                            if (!product.product) return;
                            return <ProductItem data={product} small={true} updateProduct={updateProduct} />;
                        })}
                    </div>
                </div>}
            </div>
        </div>
    );
};

export default ProductPopup